<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ user ? user.name : '' }}
            </p>
            <span class="user-status">{{ user ? user.enterprise_name : '' }}</span>
          </div>
          <img
            v-if="profilePictureURL"
            :src="profilePictureURL"
            alt=""
            class="profile-picture"
          >
          <b-avatar
            v-else
            :src="require('@/assets/images/avatars/user.png')"
            badge
            badge-variant="success"
            class="badge-minimal"
            size="40"
            variant="light-primary"
          />
        </template>

        <b-dropdown-item :to="{ name: 'profile' }" link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <div v-if="$can('Manage', 'All')">
          <b-dropdown-divider />
          <b-dropdown-item-button
            v-if="currentCountry !== 'uk'"
            @click="switchCountry('uk')"
          >
            <feather-icon size="16" icon="MapPinIcon" class="mr-50" />
            <span>Switch to UK</span>
          </b-dropdown-item-button>

          <b-dropdown-item-button v-if="currentCountry !== 'aus'" link-class="d-flex align-items-center" style="width:100%" @click="switchCountry('aus')">
            <feather-icon size="16" icon="MapPinIcon" class="mr-50" />
            <span>Switch to AUS</span>
          </b-dropdown-item-button>

        </div>

        <b-dropdown-divider />

        <b-dropdown-item v-if="$can('Create', 'Issue')" link-class="d-flex align-items-center" :to="{ name: 'issues' }">
          <feather-icon size="16" icon="MessageSquareIcon" class="mr-50" />
          <span>Submit issue</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import auth from '@/auth/auth'
import countryService from '@/services/CountryService';

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
    currentCountry: () => countryService.country,
    profilePictureURL() {
      if (!this.$store.state.user.profilePicture) return "";
      if (
        !this.$store.state.user.profilePicture.filename ||
        !this.$store.state.user.profilePicture.file_location
      )
        return "";
      return `${countryService.getApiUrl()}/files/${
        this.$store.state.user.profilePicture.file_location
      }/${this.$store.state.user.profilePicture.filename}`;
    },
  },
  methods: {
    logout() {
      auth.logout();
      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    switchCountry(country) {
      countryService.updateCountry(country)
      window.location.reload();
    },
  },
}
</script>
<style>
.dropdown-menu button.dropdown-item {
  width: 100%;
}

.profile-picture {
  width: 50px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
}
</style>
